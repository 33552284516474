import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { userAppContext } from '../../Context/Context.jsx';
import fetchEndpoint from '../../Services/fetchEndpoint.jsx';

export default function AdvancedSearch() {
  const navigate = useNavigate();
  const [searchType, setSearchType] = useState('DISEASES');
  const [searchInput, setSearchInput] = useState('');
  const [file, setFile] = useState(null);
  const [notFound, setNotFound] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { authorization, isAuthenticated, setAuthError } = userAppContext();

  const exampleData = {
    DISEASES: [
      'C0002395',
      'C1834674',
      'C0432288',
      'C1336030',
      'C1562113',
      'C0018522',
      'C0041351',
      'C1855305',
      'C0018854',
      'C2677500',
      'C0343111',
      'CX343111',
    ],
    GENES: [
      '3718',
      '3717',
      '9832',
      '282973',
      '152789',
      '3716',
      '7143',
      '29085',
      '8867',
      '55333',
      '8871',
      'X8871',
    ],
    VARIANTS: [
      'rs75290158',
      'rs142200609',
      'rs893051',
      'rs1555979575',
      'rs1567404609',
      'rs769599110',
      'rs776564488',
      'rs219780',
      'rs773766654',
      'rs139437157',
      'rs994615490',
      'rs200527665',
      'rs796988312',
      'rx796988312',
    ],
    CHEMICALS: [
      'C0210657',
      'C0008024',
      'C0055278',
      'C0935918',
      'C1832037',
      'C1143018',
      'C0085994',
      'C428779',
      'CX28779',
    ],
  };

  const matchItem = (entry, id) => {
    const idLower = id.toString().toLowerCase();

    const matchFields = {
      DISEASES: ['diseaseID', 'diseaseUMLSCUI', 'id', 'diseaseName', 'name'],
      GENES: ['geneID', 'geneEnsemblIDs', 'geneNcbiID', 'symbol', 'description'],
      VARIANTS: ['strID', 'geneSymbol'],
      CHEMICALS: ['chemUMLSCUI', 'chemShowName'],
    };

    return matchFields[searchType]?.some(field => {
      const value = entry[field];
      return value && value.toString().toLowerCase() === idLower;
    });
  };

  const getIdFromItem = item => {
    const idMap = {
      DISEASES: item.diseaseUMLSCUI,
      GENES: item.geneID,
      VARIANTS: item.strID,
      CHEMICALS: item.chemUMLSCUI,
    };
    return idMap[searchType];
  };

  const handleSearch = () => {
    if (!searchInput.trim()) {
      alert('Please enter at least one ID.');
      return;
    }
    setIsLoading(true);

    const ids = searchInput
      .replace(/[\n,]+/g, '-')
      .replace(/-+/g, '-')
      .split('-')
      .filter(Boolean);

    //todo css
    if (ids.length > 100) {
      setShowPopup(true);
      setIsLoading(false);
      return;
    }

    const tabValue = searchType === 'VARIANTS' ? 'VDA' : 'GDA';

    Promise.all(
      ids.map(id => {
        const urlEndpoint = `api/search?type=${searchType}&pattern=${id}${!isAuthenticated ? '&skey=unauth' : ''}`;
        return fetchEndpoint(urlEndpoint, authorization, setAuthError)
          .then(res => {
            const data = res?.payload?.[searchType.toLowerCase()] || [];
            const item = data.find(entry => matchItem(entry, id));
            return item ? { id, fullId: getIdFromItem(item) } : { id, fullId: null };
          })
          .catch(error => {
            console.error('Error:', error);
            return { id, fullId: null };
          });
      }),
    ).then(results => {
      const foundItems = results.filter(result => result.fullId !== null).map(result => result.fullId);
      const notFoundItems = results.filter(result => result.fullId === null).map(result => result.id);

      setNotFound(notFoundItems);

      if (foundItems.length > 0) {
        const idents = foundItems.join('-');
        const view = searchType;
        const url = `/search?view=${view}&idents=${idents}&source=ALL&tab=${tabValue}`;
        navigate(url);
      }
      setIsLoading(false);
    });
  };

  const handleShowExamples = () => {
    const examples = exampleData[searchType];
    setSearchInput(examples.join(', '));
  };

  const handleFileUpload = event => {
    const file = event.target.files[0];
    if (file) {
      setFile(file);
      extractTextFromFile(file);
    }
  };

  const extractTextFromFile = file => {
    const reader = new FileReader();
    reader.onload = () => {
      let text = reader.result;
      if (file.name.endsWith('.tsv')) {
        text = text.replace(/\t/g, ',');
      }
      text = text.replace(/"(.*?)"/g, '$1');

      setSearchInput(text);
    };
    reader.readAsText(file);
  };

  const toggleInfo = () => {
    setShowInfo(prev => !prev);
  };

  const handleClear = () => {
    setSearchInput('');
    setFile(null);
    setNotFound([]);
    document.getElementById('file-upload').value = '';
  };

  useEffect(() => {
    if (showPopup) {
      const timer = setTimeout(() => {
        setShowPopup(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [showPopup]);

  return (
    <div className="multiple-searches">
      <div className="ms-radio-group" onChange={e => setSearchType(e.target.value)}>
        <label className="custom-radio-label">
          <input type="radio" name="searchType" value="DISEASES" defaultChecked={searchType === 'DISEASES'} />
          <span className="custom-radio"></span>Diseases
        </label>
        <label className="custom-radio-label">
          <input type="radio" name="searchType" value="VARIANTS" defaultChecked={searchType === 'VARIANTS'} />
          <span className="custom-radio"></span> Variants
        </label>
        <label className="custom-radio-label">
          <input type="radio" name="searchType" value="GENES" defaultChecked={searchType === 'GENES'} />
          <span className="custom-radio"></span>Genes
        </label>
        <label className="custom-radio-label">
          <input
            type="radio"
            name="searchType"
            value="CHEMICALS"
            defaultChecked={searchType === 'CHEMICALS'}
          />
          <span className="custom-radio"></span> Chemicals
        </label>
      </div>
      <div className="ms-input-container">
        <textarea
          className="ms-text"
          placeholder="List should be separated by space, comma, hyphen, or line breaks"
          value={searchInput}
          rows="5"
          onChange={e => setSearchInput(e.target.value)}
        ></textarea>
        <button className="d-flex justify-content-end" onClick={toggleInfo}>
          <i className="bi bi-question-circle-fill"></i>
        </button>
        {showInfo && (
          <div className="">
            <p>
              <strong>List should be separated by comma, hyphen, or line breaks.</strong>
              <br />
              <strong>Examples:</strong>
              <br />
              C0002395, C1834674, C0432288, C1336030 or
              <br />
              C0002395 - C1834674 - C0432288 - C1336030 or
              <br />
              C0002395
              <br />
              C1834674
              <br />
              C0432288
            </p>
            <p>
              <strong>Accepted files formats:</strong> .txt, .csv, .tsv
            </p>
          </div>
        )}
        <div className="ms-buttons">
          <input
            type="file"
            accept=".txt, .csv, .tsv"
            onChange={handleFileUpload}
            style={{ display: 'none' }}
            id="file-upload"
          />
          <button
            className="btn-gradient-pink"
            onClick={() => document.getElementById('file-upload').click()}
            title="Accepted file formats: .txt, .csv, .tsv"
          >
            Upload
          </button>
          <button
            className="btn-gradient-pink"
            onClick={handleShowExamples}
            title="Items should be separated by space, comma, or line breaks."
          >
            Show Examples
          </button>
          <button
            className="btn-gradient-pink"
            onClick={handleSearch}
            title="Items should be separated by space, comma, hyphen, or line breaks"
          >
            Search
          </button>
          <button className="btn-gradient-pink" onClick={handleClear}>
            Clear
          </button>
        </div>
      </div>
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading" />
        </div>
      )}

      {notFound.length > 0 && (
        <div className="row ms-results">
          <div className="col-12 p-4">
            <h4>Items Not Found in the DB:</h4>
            <div className="advanced-notfound-roll">
              <ul>
                {notFound.map(item => (
                  <li key={item}>
                    <strong>{item}</strong>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
      {showPopup && (
        <div className="popup-overlay">
          <div className="popup-content">
            <p>You can search a maximum of 100 items at a time.</p>
            <button onClick={() => setShowPopup(false)}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
}

//add limit de 100 itens
