import React from 'react';
import { Link } from 'react-router-dom';

function SummaryBlog() {
  return (
    <section>
      <h2 className="mt-3">
        <Link className="gradient-color" to="/blog">
          BLOG
        </Link>
      </h2>
      <Link to="/blog/disgenet-academic-license-guide" className="summary-list text-wrap">
        DISGENET Academic License: Eligibility, Application & Free Access
      </Link>
      <Link to="/blog/disgenet-nlp-to-accelerate-drug-development" className="summary-list text-wrap">
        DISGENET NLP to Accelerate Drug Development
      </Link>
    </section>
  );
}

export default SummaryBlog;
