import React from 'react';
import SearchHeader from '../Components/Headers/SearchHeader';
import Footer from '../Components/Home/Footer';
import SearchResults from '../Components/Search/SearchResults';
import { searchContext, userAppContext } from '../Context/Context';
import LoadingSpinner from '../Components/UI/LoadingSpinner';
import ChatbotButton from '../Components/ChatBot/ChatButton';

export default function Search() {
  const { view, source, tab, hasValidParams } = searchContext();

  return (
    <>
      <SearchHeader />
      {hasValidParams && view && source && tab ? (
        <div>
          <SearchResults />
        </div>
      ) : (
        <LoadingSpinner />
        // <div>
        //   <p>Bad state </p>
        //   <p>Valid params: {hasValidParams ? hasValidParams : 'EMPTY!'}</p>
        //   <p>View: {view ? view : 'EMPTY!'}</p>
        //   <p>Source: {source ? source : 'EMPTY!'}</p>
        //   <p>Tab: {tab ? tab : 'EMPTY!'}</p>
        // </div>
      )}
      <Footer />
    </>
  );
}
