import { reference } from '@popperjs/core';

// Desc: Column visibility models for ReactTableGrid views
const ColumnVisibilityModels = {
  GENES_ALL: {
    symbol: true,
    description: true,
    proteinIDs: false,
    proteinClassIDs: true,

    ncbi_type: false,
    nct_id: true,
    pathwayName: false,
    pathwayID: false,

    numDiseasesAssociatedToGene: true,
    numVariantsAssociatedToGene: true,
    DSI: true,
    DPI: true,

    PLI: true,
    numPublications: true,
    firstRef: true,
    lastRef: true,

    ncbiID: false,
  },
  GENES_GDA_SUMMARY: {
    geneSymbol: false,
    diseaseName: true,
    numGenesAssociatedToDisease: true,
    numVariantsAssociatedToDisease: true,

    score: true,
    numPMIDsupportingAssociation: true,
    numberChemsIncludedInEvidence: true,
    numberPmidsWithChemsIncludedInEvidence: true,

    numDBSNPsupportingAssociation: true,
    yearInitial: true,
    yearFinal: true,
    EI: true,

    EL: false,
    diseaseUMLSCUI: false,
    diseaseType: true,
    diseaseClassName_MSH: true,

    diseaseClassName_UMLS: false,
    diseaseClassName_DO: false,
    diseaseClassName_HPO: false,
    geneNcbiID: false,

    geneEnsemblIDs: false,
    geneNcbiType: false,
    pathwayName: false,
    pathwayID: false,
  },
  GENES_GDA_EVIDENCE: {
    geneSymbol: false,
    diseaseName: true,
    numGenesAssociatedToDisease: false,
    numVariantsAssociatedToDisease: false,

    score: true,
    associationType: true,
    source: false,
    sentenceHTML: true,

    chemsInSentence: true,
    chemUMLSCUIs: false,
    pmid: true,
    pmYear: true,

    journal_abbrv: true,
    diseaseUMLSCUI: false,
    diseaseType: false,
    diseaseClassName_MSH: false,

    diseaseClassName_UMLS: false,
    diseaseClassName_DO: false,
    diseaseClassName_HPO: false,
    geneNcbiID: false,

    geneEnsemblIDs: false,
    geneNcbiType: false,
    pathwayName: false,
    pathwayID: false,

    chemical_effect: false,
    reference: true,
    reference_type: true,
  },
  GENES_VDA_SUMMARY: {
    geneSymbol: false,
    variantStrID: true,
    diseaseName: true,
    numDiseasesAssociatedToVariant: true,

    score: true,
    mostSevereConsequences: true,
    numPMIDsupportingAssociation: true,
    numberChemsIncludedInEvidence: false,

    numberPmidsWithChemsIncludedInEvidence: false,
    yearInitial: true,
    yearFinal: true,
    EI: true,

    variantDSI: true,
    variantDPI: true,
    chromosome: true,
    coord: true,

    alleles: false,
    alleleFreqExome: false,
    alleleFreqGenome: true,
    DBSNPclass: false,

    polyphen_score: false,
    sift_score: false,
    diseaseUMLSCUI: false,
    diseaseType: false,

    diseaseClassName_MSH: false,
    diseaseClassName_UMLS: false,
    diseaseClassName_DO: false,
    diseaseClassName_HPO: false,

    geneNcbiIDs: false,
  },
  GENES_VDA_EVIDENCE: {
    geneSymbol: false,
    variantStrID: true,
    diseaseName: true,
    numDiseasesAssociatedToVariant: true,

    score: true,
    mostSevereConsequences: false,
    gof_lof: false,
    associationType: true,

    sentenceHTML: true,
    chemsInSentence: true,
    chemUMLSCUIs: false,
    chemical_effect: false,

    pmid: true,
    pmYear: true,
    journal_abbrv: true,
    source: false,

    variantDSI: false,
    variantDPI: false,
    pValue: false,
    or_or_beta: false,

    chromosome: false,
    coord: false,
    alleleFreqExome: false,
    alleleFreqGenome: false,

    riskAllele: true,
    DBSNPclass: false,
    diseaseUMLSCUI: false,
    diseaseType: false,

    diseaseClassName_MSH: false,
    diseaseClassName_UMLS: false,
    diseaseClassName_DO: false,
    diseaseClassName_HPO: false,

    geneNcbiIDs: false,
  },
  DISEASES_ALL: {
    name: true,
    type: true,
    diseaseClassName_MSH: true,
    diseaseClassName_UMLS: true,

    diseaseClassName_DO: false,
    diseaseClassName_HPO: false,
    nct_id: true,
    numGenesAssociatedToDisease: true,

    numVariantsAssociatedToDisease: true,
    numPublications: false,
    numPublications_GDA: true,
    numPublications_VDA: true,

    firstRef: true,
    lastRef: true,
    diseaseUMLSCUI: false,
  },
  DISEASES_GDA_SUMMARY: {
    diseaseName: false,
    geneSymbol: true,
    geneDescription: true,
    numDiseasesAssociatedToGene: true,

    numVariantsAssociatedToGene: true,
    score: true,
    numPMIDsupportingAssociation: true,
    numberChemsIncludedInEvidence: true,

    numberPmidsWithChemsIncludedInEvidence: true,
    numDBSNPsupportingAssociation: true,
    yearInitial: true,
    yearFinal: true,

    EI: true,
    EL: false,
    geneDSI: true,
    geneDPI: true,

    genepLI: true,
    diseaseUMLSCUI: false,
    geneNcbiID: false,
    geneEnsemblIDs: false,

    geneProteinStrIDs: false,
    geneProteinClassNames: false,
    geneNcbiType: false,
    pathwayName: false,

    pathwayID: false,
  },
  DISEASES_GDA_EVIDENCE: {
    diseaseName: false,
    geneSymbol: true,
    geneDescription: false,
    numDiseasesAssociatedToGene: false,

    numVariantsAssociatedToGene: false,
    score: true,
    associationType: true,
    source: false,

    sentenceHTML: true,
    chemsInSentence: true,
    chemUMLSCUIs: false,
    pmid: true,

    pmYear: true,
    journal_abbrv: true,
    geneDSI: false,
    geneDPI: false,

    genepLI: false,
    diseaseUMLSCUI: false,
    geneNcbiID: false,
    geneEnsemblIDs: false,

    geneNcbiType: false,
    geneProteinStrIDs: false,
    geneProteinClassNames: false,
    pathwayName: false,

    pathwayID: false,
    chemical_effect: true,
    reference: true,
    reference_type: true,
  },
  DISEASES_VDA_SUMMARY: {
    diseaseName: false,
    variantStrID: true,
    geneSymbol: true,
    numDiseasesAssociatedToVariant: true,

    score: true,
    mostSevereConsequences: true,
    numPMIDsupportingAssociation: true,
    numberChemsIncludedInEvidence: false,

    numberPmidsWithChemsIncludedInEvidence: false,
    yearInitial: true,
    yearFinal: true,
    EI: true,

    variantDSI: true,
    variantDPI: true,
    chromosome: true,
    coord: true,

    alleles: false,
    alleleFreqExome: false,
    alleleFreqGenome: true,
    DBSNPclass: false,

    polyphen_score: false,
    sift_score: false,
    diseaseUMLSCUI: false,
    geneNcbiIDs: false,
  },
  DISEASES_VDA_EVIDENCE: {
    diseaseName: false,
    variantStrID: true,
    geneSymbol: true,
    numDiseasesAssociatedToVariant: false,

    score: true,
    mostSevereConsequences: false,
    gof_lof: true,
    chemical_effect: false,

    associationType: true,
    sentenceHTML: true,
    chemsInSentence: true,
    chemUMLSCUIs: false,

    pmid: true,
    pmYear: true,
    journal_abbrv: true,
    source: false,

    variantDSI: false,
    variantDPI: false,
    pValue: false,
    or_or_beta: false,

    chromosome: false,
    coord: false,
    alleleFreqExome: false,
    alleleFreqGenome: false,

    riskAllele: true,
    DBSNPclass: false,
    diseaseUMLSCUI: false,
    geneNcbiIDs: false,
  },
  DISEASES_DDA_SUMMARY: {
    disease1_Name: false,
    ngenes_diseaseID_1: false,
    nvariants_diseaseID_1: false,
    disease1ClassName_DO: false,

    disease1ClassName_HPO: false,
    disease1ClassName_MSH: false,
    disease1ClassName_UMLS: false,
    disease1_Type: false,

    disease2_Name: true,
    ngenes_diseaseID_2: true,
    nvariants_diseaseID_2: true,
    disease2ClassName_DO: false,

    disease2ClassName_HPO: false,
    disease2ClassName_MSH: true,
    disease2ClassName_UMLS: false,
    disease2_Type: false,

    shared_genes: true,
    jaccard_genes: true,
    pvalue_jaccard_genes: true,
    shared_variants: true,

    jaccard_variants: true,
    pvalue_jaccard_variants: true,
    sokal: true,
    ddaRelation: true,
  },
  ngenes_diseaseID_SUMMARY: {
    geneSymbol: true,
    geneProteinStrIDs: true,
    geneDescription: true,
    geneProteinClassNames: true,

    geneDSI: true,
    geneDPI: true,
    genepLI: true,
  },
  nvariants_diseaseID_SUMMARY: {
    geneSymbol: true,
    variantStrID: true,
    variantDSI: true,
    variantDPI: true,

    mostSevereConsequences: true,
    chromosome: true,
    coord: true,
    alleles: true,

    alleleFreqExome: true,
    alleleFreqGenome: true,
    DBSNPclass: true,
    numDiseasesAssociatedToVariant: true,
  },
  VARIANTS_ALL: {
    strID: true,
    geneSymbol: true,
    polyphen_score: false,
    sift_score: false,

    numDiseasesAssociatedToVariant: true,
    DSI: true,
    DPI: true,
    chromosome: false,

    coord: false,
    DBSNPclass: false,
    mostSevereConsequences: true,
    alleles: true,

    exome: true,
    genome: true,
    numPublications: true,
    firstRef: true,

    lastRef: true,
  },
  VARIANTS_VDA_SUMMARY: {
    variantStrID: true,
    diseaseName: true,
    geneSymbol: true,
    score: true,

    mostSevereConsequences: true,
    numPMIDsupportingAssociation: true,
    numberChemsIncludedInEvidence: false,
    numberPmidsWithChemsIncludedInEvidence: false,

    yearInitial: true,
    yearFinal: true,
    EI: true,
    variantDSI: true,

    variantDPI: true,
    chromosome: true,
    coord: true,
    alleles: false,

    exome: false,
    alleleFreqGenome: true,
    DBSNPclass: false,
    polyphen_score: false,

    sift_score: false,
    diseaseUMLSCUI: false,
    diseaseType: false,
    diseaseClassName_MSH: false,

    diseaseClassName_UMLS: false,
    diseaseClassName_DO: false,
    diseaseClassName_HPO: false,
    geneNcbiIDs: false,
  },
  VARIANTS_VDA_EVIDENCE: {
    variantStrID: false,
    diseaseName: true,
    geneSymbol: true,
    score: true,

    gof_lof: false,
    associationType: true,
    sentenceHTML: true,
    chemsInSentence: true,

    chemUMLSCUIs: false,
    chemical_effect: false,
    pmid: true,
    pmYear: true,

    journal_abbrv: true,
    source: false,
    pValue: false,
    or_or_beta: false,

    riskAllele: true,
    diseaseUMLSCUI: false,
    diseaseType: false,
    diseaseClassName_MSH: false,

    diseaseClassName_UMLS: false,
    diseaseClassName_DO: false,
    diseaseClassName_HPO: false,
    geneNcbiIDs: false,
  },
  CHEMICALS_ALL: {
    chemUMLSCUI: false,
    chemShowName: true,
    chemNumGDAs: true,
    chemNumPublicationsGDAs: true,

    chemNumVDAs: true,
    chemNumPublicationsVDAs: true,
    chemNumPublications: false,
  },
  CHEMICALS_GDA_SUMMARY: {
    geneSymbol: true,
    geneDescription: true,
    diseaseName: true,
    numDiseasesAssociatedToGene: true,

    numDBSNPsupportingAssociation: false,
    numGenesAssociatedToDisease: true,
    numVariantsAssociatedToDisease: false,
    score: true,

    numberPmidsWithChemsFiltered: true,
    numberChemsIncludedInEvidence: true,
    numVariantsAssociatedToGene: true,
    yearInitial: true,

    yearFinal: true,
    EI: true,
    EL: true,
    geneDSI: true,

    geneDPI: true,
    genepLI: true,
    diseaseUMLSCUI: false,
    diseaseType: true,

    diseaseClassName_MSH: true,
    diseaseClassName_UMLS: false,
    diseaseClassName_DO: false,
    diseaseClassName_HPO: false,

    geneNcbiID: false,
    geneEnsemblIDs: false,
    geneNcbiType: false,
    geneProteinStrIDs: false,

    geneProteinClassNames: false,
    pathwayName: false,
    pathwayID: false,
  },
  CHEMICALS_GDA_EVIDENCE: {
    geneSymbol: true,
    geneDescription: false,
    diseaseName: true,
    numDiseasesAssociatedToGene: false,

    numVariantsAssociatedToGene: false,
    numGenesAssociatedToDisease: false,
    numVariantsAssociatedToDisease: false,
    score: true,

    associationType: true,
    source: false,
    geneNcbiType: false,
    sentenceHTML: true,

    chemsInSentence: true,
    chemUMLSCUIs: false,
    pmid: true,
    pmYear: true,

    journal_abbrv: true,
    geneDSI: false,
    geneDPI: false,
    genepLI: false,

    diseaseUMLSCUI: false,
    diseaseType: false,
    diseaseClassName_MSH: false,
    diseaseClassName_UMLS: false,

    diseaseClassName_DO: false,
    diseaseClassName_HPO: false,
    geneNcbiID: false,
    geneEnsemblIDs: false,

    geneProteinStrIDs: false,
    geneProteinClassNames: false,
    pathwayName: false,
    pathwayID: false,

    chemical_effect: false,
    reference: true,
    reference_type: true,
  },
  CHEMICALS_VDA_SUMMARY: {
    variantStrID: true,
    diseaseName: true,
    geneSymbol: true,
    numDiseasesAssociatedToVariant: true,

    score: true,
    mostSevereConsequences: true,
    numberChemsIncludedInEvidence: false,
    numberPmidsWithChemsFiltered: true,

    yearInitial: true,
    yearFinal: true,
    EI: true,
    variantDSI: true,

    variantDPI: true,
    chromosome: false,
    coord: false,
    alleles: false,

    alleleFreqExome: false,
    alleleFreqGenome: true,
    DBSNPclass: false,
    polyphen_score: false,

    sift_score: false,
    diseaseUMLSCUI: false,
    diseaseType: false,
    diseaseClassName_MSH: false,

    diseaseClassName_UMLS: false,
    diseaseClassName_DO: false,
    diseaseClassName_HPO: false,
    geneNcbiIDs: false,
  },
  CHEMICALS_VDA_EVIDENCE: {
    variantStrID: true,
    diseaseName: true,
    geneSymbol: true,
    numDiseasesAssociatedToVariant: false,

    score: true,
    mostSevereConsequences: false,
    gof_lof: false,
    associationType: true,

    sentenceHTML: true,
    chemsInSentence: true,
    chemUMLSCUIs: false,
    pmid: true,

    pmYear: true,
    journal_abbrv: true,
    source: false,
    variantDSI: false,

    variantDPI: false,
    pValue: false,
    or_or_beta: false,
    chromosome: false,

    coord: false,
    alleleFreqExome: false,
    alleleFreqGenome: false,
    riskAllele: true,

    DBSNPclass: false,
    diseaseUMLSCUI: false,
    diseaseType: false,
    diseaseClassName_MSH: false,

    diseaseClassName_UMLS: false,
    diseaseClassName_DO: false,
    diseaseClassName_HPO: false,
    geneNcbiIDs: false,

    chemical_effect: false,
  },
  GDA_ALL_SUMMARY: {
    geneSymbol: true,
    geneDescription: true,
    diseaseName: true,
    numDiseasesAssociatedToGene: true,

    numVariantsAssociatedToGene: false,
    numGenesAssociatedToDisease: true,
    numVariantsAssociatedToDisease: false,
    score: true,

    numPMIDsupportingAssociation: true,
    numberChemsIncludedInEvidence: true,
    numberPmidsWithChemsIncludedInEvidence: true,
    numDBSNPsupportingAssociation: true,

    yearInitial: true,
    yearFinal: true,
    EI: true,
    EL: true,

    geneDSI: true,
    geneDPI: true,
    genepLI: true,
    diseaseUMLSCUI: false,

    diseaseType: true,
    diseaseClassName_MSH: true,
    diseaseClassName_UMLS: false,
    diseaseClassName_DO: false,

    diseaseClassName_HPO: false,
    geneNcbiID: false,
    geneEnsemblIDs: false,
    geneNcbiType: false,

    geneProteinStrIDs: false,
    geneProteinClassNames: false,
    pathwayName: false,
    pathwayID: false,
  },
  GDA_ALL_EVIDENCE: {
    geneSymbol: true,
    geneDescription: false,
    diseaseName: true,
    numDiseasesAssociatedToGene: false,

    numVariantsAssociatedToGene: false,
    numGenesAssociatedToDisease: false,
    numVariantsAssociatedToDisease: false,
    score: true,

    associationType: true,
    source: false,
    geneNcbiType: false,
    sentenceHTML: true,

    chemsInSentence: true,
    chemUMLSCUIs: false,
    // pmid: false,
    pmYear: true,

    journal_abbrv: true,
    geneDSI: false,
    geneDPI: false,
    genepLI: false,

    diseaseUMLSCUI: false,
    diseaseType: false,
    diseaseClassName_MSH: false,
    diseaseClassName_UMLS: false,

    diseaseClassName_DO: false,
    diseaseClassName_HPO: false,
    geneNcbiID: false,
    geneEnsemblIDs: false,

    geneProteinStrIDs: false,
    geneProteinClassNames: false,
    pathwayName: false,
    pathwayID: false,
    chemical_effect: false,
    reference: true,
    reference_type: true,
  },
  VDA_ALL_SUMMARY: {
    variantStrID: true,
    diseaseName: true,
    geneSymbol: true,
    numDiseasesAssociatedToVariant: true,

    score: true,
    mostSevereConsequences: true,
    numPMIDsupportingAssociation: true,
    numberChemsIncludedInEvidence: false,

    numberPmidsWithChemsIncludedInEvidence: false,
    yearInitial: true,
    yearFinal: true,
    EI: true,

    variantDSI: true,
    variantDPI: true,
    chromosome: false,
    coord: false,

    alleles: false,
    alleleFreqExome: false,
    alleleFreqGenome: true,
    DBSNPclass: false,

    polyphen_score: false,
    sift_score: false,
    diseaseUMLSCUI: false,
    diseaseType: false,

    diseaseClassName_MSH: false,
    diseaseClassName_UMLS: false,
    diseaseClassName_DO: false,
    diseaseClassName_HPO: false,

    geneNcbiIDs: false,
  },
  VDA_ALL_EVIDENCE: {
    variantStrID: true,
    diseaseName: true,
    geneSymbol: true,
    numDiseasesAssociatedToVariant: false,

    score: true,
    mostSevereConsequences: false,
    gof_lof: false,
    associationType: true,

    sentenceHTML: true,
    chemsInSentence: true,
    chemUMLSCUIs: false,
    chemical_effect: false,

    // pmid: true,
    pmYear: true,
    journal_abbrv: true,
    source: false,

    variantDSI: false,
    variantDPI: false,
    pValue: false,
    or_or_beta: false,

    chromosome: false,
    coord: false,
    alleleFreqExome: false,
    alleleFreqGenome: false,

    riskAllele: true,
    DBSNPclass: false,
    diseaseUMLSCUI: false,
    diseaseType: false,

    diseaseClassName_MSH: false,
    diseaseClassName_UMLS: false,
    diseaseClassName_DO: false,
    diseaseClassName_HPO: false,

    geneNcbiIDs: false,
    reference: true,
    reference_type: true,
  },
};

// Adding custom mapping columnLists
ColumnVisibilityModels['numberChemsIncludedInEvidenceGDA_SUMMARY'] = {
  chemUMLSCUI: false,
  chemShowName: true,
  chemNumGDAs: true,
  chemNumPublicationsGDA: true,

  chemNumPublicationsGDAs: true,
  chemNumVDAs: true,
  chemNumPublicationsVDAs: true,
  chemNumPublications: false,
};
ColumnVisibilityModels['numberChemsIncludedInEvidenceVDA_SUMMARY'] = {
  chemUMLSCUI: false,
  chemShowName: true,
  chemNumVDAs: true,
  chemNumPublicationsVDA: true,

  chemNumPublicationsVDAs: true,
  chemNumGDAs: true,
  chemNumPublicationsGDAs: true,
  chemNumPublications: false,
};
ColumnVisibilityModels['numberPmidsWithChemsIncludedInEvidenceGDA_EVIDENCE'] =
  ColumnVisibilityModels['GDA_ALL_EVIDENCE'];
ColumnVisibilityModels['numberPmidsWithChemsIncludedInEvidenceVDA_EVIDENCE'] =
  ColumnVisibilityModels['VDA_ALL_EVIDENCE'];
ColumnVisibilityModels['numberPmidsWithChemsFilteredVDA_EVIDENCE'] =
  ColumnVisibilityModels['VDA_ALL_EVIDENCE'];
ColumnVisibilityModels['numberPmidsWithChemsFilteredGDA_EVIDENCE'] =
  ColumnVisibilityModels['GDA_ALL_EVIDENCE'];
ColumnVisibilityModels['numPMIDsupportingAssociationGDA_EVIDENCE'] =
  ColumnVisibilityModels['GDA_ALL_EVIDENCE'];
ColumnVisibilityModels['numPMIDsupportingAssociationVDA_EVIDENCE'] =
  ColumnVisibilityModels['VDA_ALL_EVIDENCE'];

Object.defineProperty(ColumnVisibilityModels, 'getByViewTab', {
  // This method is used to get the column visibility model by view, tab, and isEvidence
  // the configuration dictionary keys are codified as: <TAB>_<VIEW>_<TYPE>
  value: function (VIEW, TAB, isEvidence) {
    // if view = 'ALL', entities _ALL view, not summary
    // if isEvidence is provided, override to evidence view
    let type;
    type = VIEW === 'ALL' ? 'ALL' : 'SUMMARY';
    type = isEvidence ? 'EVIDENCE' : type;

    // match the key that contains the view, tab, and type
    const matchedKey = Object.keys(this)
      .filter(key => key.includes(VIEW) && key.includes(TAB))
      .filter(key => key.includes(type))[0];
    return matchedKey ? this[matchedKey] : undefined;
  },
  enumerable: false, // This keeps the custom method from being counted as one of the list items
});

Object.defineProperty(ColumnVisibilityModels, 'getByMappingName', {
  // This method is used to get the column visibility model by mapping name and isEvidence
  // the keys are synchronized with the "name" field of the Column.jsx component
  // codified as <MAPPING_NAME>_<TYPE>
  value: function (mappingName, isEvidence) {
    // Either a summary or evidence grid
    let type;
    type = isEvidence ? '_EVIDENCE' : '_SUMMARY';

    // match the key that contains the mapping name and type
    const matchedKey = Object.keys(this)
      .filter(key => key.includes(mappingName))
      .filter(key => key.includes(type))[0];
    return matchedKey ? this[matchedKey] : undefined;
  },
  enumerable: false, // This makes the property non-enumerable
});

export { ColumnVisibilityModels };
