import React, { useEffect } from 'react';
import '@aws-amplify/ui-react/styles.css';
import Header from '../Components/Headers/Header';
import Container from '../Components/Home/Container';
import Container2 from '../Components/Home/Container2';
import SearchBar from '../Components/Search/SearchBar';
import BrowserButton from '../Components/Home/BrowserButton';
import BlogHome from '../Components/Home/BlogHome';
import Footer from '../Components/Home/Footer';
import ChatbotButton from '../Components/ChatBot/ChatButton';
import $ from 'jquery';

function Home() {
  useEffect(() => {
    const handleSearchFocus = () => {
      document.querySelector('.container').scrollIntoView({ behavior: 'smooth' });
    };
    $('.search-home input').focus(handleSearchFocus);
    return () => {
      $('.search-home input').off('focus', handleSearchFocus);
    };
  }, []);

  return (
    <React.Fragment>
      <title> DISGENET: The most complete genomics platform - Home</title>
      <meta
        name="description"
        content="Genomics platform covering all disease areas and metrics to support different applications in precision medicine and drug discovery.."
        data-rh="true"
      />
      <link rel="canonical" href="/" />
      <Header />
      <div className="container">
        <div style={{ marginBottom: '50px' }}>
          <Container />
          <div className="search-home">
            <SearchBar />
          </div>
        </div>
        <div style={{ marginBottom: '80px' }}>
          <Container2 />
        </div>
        <div style={{ marginBottom: '150px' }}>
          <BlogHome />
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default Home;
