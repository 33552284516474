import React from 'react';
import { useLocation } from 'react-router-dom';
import CommonHeader from '../../Components/Headers/CommonHeader.jsx';
import { BlogPost } from '../../Components/Blog/BlogContent.jsx';
import { BlogPostDetails } from '../../Components/Blog/BlogPostDetails.jsx';
import { posts } from '../../Components/Blog/Posts.jsx';
import SummaryBlog from '../../Components/Blog/SummaryBlog.jsx';
import Footer from '../../Components/Home/Footer.jsx';

export default function PostPage() {
  return (
    <React.Fragment>
      <CommonHeader />
      <div className="row p-4">
        <div className="col-2">
          <SummaryBlog />
        </div>
        <div className="col-8">
          <BlogPostDetails posts={posts} />
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
}
