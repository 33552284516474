import React from 'react';
import { Helmet } from 'react-helmet-async';
import CommonHeader from '../Components/Headers/CommonHeader.jsx';
import AboutContent from '../Components/About/AboutContent.jsx';
import Footer from '../Components/Home/Footer.jsx';
import $ from 'jquery';
import ChatbotButton from '../Components/ChatBot/ChatButton';

export default function About() {
  $('.popover').hide();

  return (
    <React.Fragment>
      <Helmet>
        <title>DISGENET: Database Information</title>
        <meta name="description" content="Information about database statistics, attributes and metrics." />
        <link rel="canonical" href="/About" />
      </Helmet>

      <CommonHeader />
      <AboutContent />
      <Footer />
    </React.Fragment>
  );
}
