import React from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { posts } from './Posts';

const BlogPostDetails = () => {
  const { postName } = useParams();
  const post = posts.find(post => post.postName === postName);

  if (!post) {
    return <div className="container">Post not found.</div>;
  }

  return (
    <div className="container pt-4 pb-4">
      <Helmet>
        <title>{post.title}</title>
        <meta name="description" content={post.description} />
        <meta name="keywords" content={post.tags?.join(', ')} />
        <meta property="og:title" content={post.title} />
        <meta property="og:description" content={post.description} />
      </Helmet>
      <div id={post.postName} className="post-preview" dangerouslySetInnerHTML={{ __html: post.content }} />
    </div>
  );
};

export { posts, BlogPostDetails };
