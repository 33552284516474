const posts = [
  {
    title: '1DISGENET Academic License: Eligibility, Application & Free Access',
    postName: 'disgenet-academic-license-guide',
    category: ['Product'],
    tags: [
      'am-i-eligible-for-the-disgenet-academic-license',
      'disgenet-academic-license',
      'disgenet-academic-plans',
      'disgenet-for-academics',
      'disgenet-com',
      'free-access-disgenet',
      'gene-disease-database',
    ],
    date: 'August 2, 2024',
    image: '../../static/images/blog/image-post1-1024x613.png',
    description:
      'DISGENET is the most extensive and reliable gene-disease association network, providing the latest and most relevant data in the field. It was created by MedBioinformatics, and as an academic or not-for-profit researcher, you can access DISGENET’s core biomedical data at no cost. For deeper insights, we offer additional  [...]',
    content: '',
  },
  {
    title: '2DISGENET NLP to Accelerate Drug Development',
    postName: 'disgenet-nlp-to-accelerate-drug-development',
    category: ['Article', 'Product'],
    tags: ['Bioinformatics'],
    date: 'July 19, 2024',
    image: '../../static/images/blog/July-2024-list-.jpg',
    description:
      'With DISGENET, you can easily unlock new insights and greatly accelerate your drug development journey. Every year, more than 1 million papers enter PubMed in the biomedical field [1]. While medical knowledge doubles every 73 days and is increasing at an exponential rate with no evidence of slowing [2], drug discovery and development remain a [...]',
    content: '',
  },
  // ---
  {
    title: '3DISGENET Academic License: Eligibility, Application & Free Access',
    postName: 'disgenet-academic-license-guide',
    category: ['Product'],
    tags: [
      'am-i-eligible-for-the-disgenet-academic-license',
      'disgenet-academic-license',
      'disgenet-academic-plans',
      'disgenet-for-academics',
      'disgenet-com',
      'free-access-disgenet',
      'gene-disease-database',
    ],
    date: 'August 2, 2024',
    image: '../../static/images/blog/image-post1-1024x613.png',
    description:
      'DISGENET is the most extensive and reliable gene-disease association network, providing the latest and most relevant data in the field. It was created by MedBioinformatics, and as an academic or not-for-profit researcher, you can access DISGENET’s core biomedical data at no cost. For deeper insights, we offer additional options utilizing state-of-the-art NLP technology. In this [...]',
    content: '',
  },
  {
    title: '4DISGENET Academic License: Eligibility, Application & Free Access',
    postName: 'disgenet-academic-license-guide',
    category: ['Product'],
    tags: [
      'am-i-eligible-for-the-disgenet-academic-license',
      'disgenet-academic-license',
      'disgenet-academic-plans',
      'disgenet-for-academics',
      'disgenet-com',
      'free-access-disgenet',
      'gene-disease-database',
    ],
    date: 'August 2, 2024',
    image: '../../static/images/blog/image-post1-1024x613.png',
    description:
      'DISGENET is the most extensive and reliable gene-disease association network, providing the latest and most relevant data in the field. It was created by MedBioinformatics, and as an academic or not-for-profit researcher, you can access DISGENET’s core biomedical data at no cost. For deeper insights, we offer additional options utilizing state-of-the-art NLP technology. In this [...]',
    content: '',
  },
];

function generateContent(post) {
  const catHtml = post.category
    .map(category => `<a href="category/${category}" target="_blank">${category.replace(/-/g, ' ')}</a>`)
    .join(', ');

  switch (post.postName) {
    case 'disgenet-academic-license-guide':
      return `
    <div>
      <h2 class="blog-title">DISGENET Academic License: Eligibility, Application & Free Access</h2>
        <p class="blog-date">Posted on <strong>August 2, 2024</strong></p>
        <div class="blog-img">
        <img src="../../static/images/blog/Image-post2-8.png" alt="DISGENET Academic License" class="blog-image" />
        </div>
        <p class="blog-paragraph">
        DISGENET is the most extensive and reliable gene-disease association network, providing the latest and most relevant data in the field. It was created by <a href="https://www.medbioinformatics.com" class="blog-link">MedBioinformatics</a>, and as an academic or
        not-for-profit researcher, you can access DISGENET’s core biomedical data at no cost. For deeper
        insights, we offer additional options utilizing state-of-the-art NLP technology.
        </p>
        <p class="blog-paragraph">In this blog, we cover everything you need to know about DISGENET as an academic or not-for-profit researcher.</p>
    </div>
    <div>
      <h2 class="blog-subtitle">DISGENET Subscription Plans for Academics</h2>
        <p class="blog-paragraph">DISGENET offers several plans to cater to different user needs. For academic and not-for-profit researchers, the <a href="/academic-apply" class="blog-link">Free Academic</a> plan is the primary option, designed to support key research activities by providing you access to fundamental bioinformatics information, including well-known disease genes and variants.</p>
        <p class="blog-paragraph">However, if you require additional data and features, DISGENET also provides the <strong>Standard</strong> and <strong>Advanced</strong> plans.</p>
        <p class="blog-paragraph">This guide will primarily focus on the Free Academic plan and its benefits. We will also briefly outline the Standard and Advanced plans for your reference.</p>
    </div>
    <div>
      <h2 class="blog-subtitle">Eligibility for the DISGENET Free Academic License</h2>
      <h5 class="blog-sub-subtitle">To qualify for the DISGENET Free Academic License, you must meet the following criteria:</h5>
        <ul class="blog-list">
          <li class="blog-paragraph"><strong>Affiliation:</strong> Be affiliated with a recognized academic institution (university, college, research institute). You must use your institutional email address when signing up.</li>
          <li class="blog-paragraph"><strong>Non-commercial Use:</strong> Use DISGENET for non-commercial academic research purposes. For more information on what is considered commercial or non-commercial, consult our FAQs or take a look at the infographic below.</li>
        </ul>
        <p class="blog-paragraph">If you meet these requirements, you can proceed with the application process.</p>
        <img src="../../static/images/faqbanner.png" alt="DISGENET Academic License" class="blog-image"  style="display: block; margin-left: auto; margin-right: auto; width: 55%; height: auto;"/>
        <br />
    </div>
    <div>
      <h2 class="blog-subtitle">Application Process for the Free Academic License</h2>
      <h5 class="blog-sub-subtitle">To apply for a DISGENET Free Academic License, please follow these steps:</h5>
        <ul class="blog-list">
          <li class="blog-paragraph"><strong>Complete the Application:</strong> Fill out the Academic License application form on our website with accurate personal details, institutional information, and supporting links (like your LinkedIn profile). Our team will review your application manually.</li>
          <li class="blog-paragraph"><strong>Review:</strong> Double-check your application for accuracy and completeness before submitting.</li>
          <li class="blog-paragraph"><strong>Verify Your Email:</strong> Confirm your email address by clicking the link in our verification email. This step is essential and expires in 24 hours.</li>
        </ul>
    </div>
    <div class="blog-quote">
      <h5>“DISGENET has been a game-changer in our research endeavors. We wholeheartedly recommend DISGENET to other researchers and colleagues working in similar domains.”</h5>
      <footer><strong>– Maria F. Anglero, Research Assistant, Pennsylvania State University</strong></footer>
    </div>
    <div>
      <h2 class="blog-subtitle">How long does it take to obtain your Free Academic License?</h2>
        <p class="blog-paragraph">You can expect to receive a notification within 7 days, via email, confirming whether your application for a Free Academic License has been approved or denied.</p>
        <p class="blog-paragraph">In order to get started right away, we’ll provide you with instant access to a 7-day free trial* of our Advanced plan, allowing you to explore DISGENET’s text-mined data and other features before your license is confirmed.</p>
        <p class="blog-paragraph">*Please note that the Advanced free trial has limitations, such as displaying only the top 30 results.</p>
    </div>
    <div>
      <h2 class="blog-subtitle">Upgrading Your DISGENET Plan</h2>
        <p class="blog-paragraph">While the <a href="/academic-apply" class="blog-link">Free Academic License</a> provides a fantastic starting point for your research, with access to a wealth of curated data on genes and disease variants, you may encounter projects requiring even more.</p>
        <p class="blog-paragraph">The <a href="/plans" class="blog-link">Standard and Advanced</a> plans offer additional features and data that may be necessary for certain research projects.</p>
    </div>
    <div>
      <h5 class="blog-sub-subtitle">DISGENET Standard & Advanced plans offer access to:</h5>
        <ul class="blog-list">
          <li class="blog-paragraph">Text-mined data</li>
          <li class="blog-paragraph">Loss of function/gain of function annotations</li>
          <li class="blog-paragraph">Clinical trial annotations</li>
          <li class="blog-paragraph">Drugs and chemical data</li>
        </ul>
        <p class="blog-paragraph">If your research demands exceed the capabilities of the Free Academic plan, consider upgrading to a paid plan. We have pricing plans specifically adapted to academics.</p>
        <img src="../../static/images/blog/Academic-plans-graphic-blog.jpg" alt="DISGENET Academic License" class="blog-image" style="display: block; margin-left: auto; margin-right: auto; width: 55%; height: auto;"/>
        <br />
        <p class="blog-paragraph">To explore your upgrade options or request a quote, please contact us <strong>info@disgenet.com</strong></p>
        
    </div>
    <div>
      <h2 class="blog-subtitle">Apply for your Free Academic License</h2>
        <p class="blog-paragraph">If you’re affiliated with a recognized academic institution and conducting non-commercial research, we encourage you to apply for the DISGENET Free Academic License today. Head to our website now and <a href="/academic-apply">fill in your academic application.</a></p>
        <h5 class="blog-sub-subtitle">Master the Platform: Watch Our 5-Minute Tutorials</h5>
        <ul class="blog-list">
        <strong>
          <li class="blog-paragraph"><a href="https://www.youtube.com/watch?v=Q4GdJ1Aym3o">Get Started Searching | DISGENET Summary & Evidence Tables</a></li>
          <li class="blog-paragraph"><a href="https://www.youtube.com/watch?v=MC4TzstfiPE">What Data is in DISGENET? [+ Customization & Visualization Options]</a></li>
          <li class="blog-paragraph"><a href="https://www.youtube.com/watch?v=vy4AppkFTus">How to Filter for Relevant Data: Filtering Options & Search Tips</a></li>
        </strong>
        </ul>
    </div>
    <div>
      <h2 class="blog-subtitle"></h2>
      <p class="blog-paragraph"><strong>Previous: <a href="disgenet-nlp-to-accelerate-drug-development">DISGENET NLP to Accelerate Drug Development</a></strong></p>
    </div>
    `;

    case 'disgenet-nlp-to-accelerate-drug-development':
      return `
   <div>
        <h2 class="blog-title">${post.title}</h2>
        <p class="blog-date">Posted on <strong>${post.date}</strong></p>
        <div class="blog-img">
          <img src="${post.image}" alt="${post.title}" class="blog-image" />
        </div>
        <p class="blog-paragraph">${post.description}</p>
        <div>
          <h2 class="blog-subtitle">The Role of NLP in Drug Development</h2>
          <p class="blog-paragraph">With DISGENET, you can easily unlock new insights and greatly accelerate your drug development journey...</p>
        </div>
        <!-- Add more specific sections as needed -->
      </div>
    `;
  }
}

posts.forEach(post => {
  post.content = generateContent(post);
});

export { posts };
